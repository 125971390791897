// Generated by ReScript, PLEASE EDIT WITH CARE

import * as NavLink from "../rescript/components/NavLink.js";
import * as JsxRuntime from "react/jsx-runtime";

function Index(props) {
  var formatter = new Intl.DateTimeFormat("en-US", {
        dateStyle: "full"
      });
  return JsxRuntime.jsxs("ol", {
              children: [
                JsxRuntime.jsx("li", {
                      children: JsxRuntime.jsxs(NavLink.make, {
                            href: "/features",
                            children: [
                              JsxRuntime.jsx("span", {
                                    children: "Features Test"
                                  }),
                              JsxRuntime.jsx("span", {
                                    className: "m-2 hidden h-[2px] flex-1 bg-stone-300 dark:bg-stone-700 md:block"
                                  }),
                              JsxRuntime.jsx("span", {
                                    children: formatter.format(new Date("2023-03-27")),
                                    className: "text-sm text-stone-600 md:text-base md:text-inherit"
                                  })
                            ],
                            className: "mb-2 flex w-full flex-col justify-between md:flex-row md:items-center"
                          })
                    }),
                JsxRuntime.jsx("li", {
                      children: JsxRuntime.jsxs(NavLink.make, {
                            href: "/blog/advent-of-code-2022-day-4-to-6",
                            children: [
                              JsxRuntime.jsx("span", {
                                    children: "Advent of Code 2022 Day 4 to 6"
                                  }),
                              JsxRuntime.jsx("span", {
                                    className: "m-2 hidden h-[2px] flex-1 bg-stone-300 dark:bg-stone-700 md:block"
                                  }),
                              JsxRuntime.jsx("span", {
                                    children: formatter.format(new Date("2023-03-27")),
                                    className: "text-sm text-stone-600 md:text-base md:text-inherit"
                                  })
                            ],
                            className: "mb-2 flex w-full flex-col justify-between md:flex-row md:items-center"
                          })
                    }),
                JsxRuntime.jsx("li", {
                      children: JsxRuntime.jsxs(NavLink.make, {
                            href: "/blog/building-a-simple-nextjs-clone-with-bun",
                            children: [
                              JsxRuntime.jsx("span", {
                                    children: "Creating a blog with Bun"
                                  }),
                              JsxRuntime.jsx("span", {
                                    className: "m-2 hidden h-[2px] flex-1 bg-stone-300 dark:bg-stone-700 md:block"
                                  }),
                              JsxRuntime.jsx("span", {
                                    children: formatter.format(new Date("2023-03-27")),
                                    className: "text-sm text-stone-600 md:text-base md:text-inherit"
                                  })
                            ],
                            className: "mb-2 flex w-full flex-col justify-between md:flex-row md:items-center"
                          })
                    }),
                JsxRuntime.jsx("li", {
                      children: JsxRuntime.jsxs(NavLink.make, {
                            href: "/blog/nothing-here",
                            children: [
                              JsxRuntime.jsx("span", {
                                    children: "Non existing blog post"
                                  }),
                              JsxRuntime.jsx("span", {
                                    className: "m-2 hidden h-[2px] flex-1 bg-stone-300 dark:bg-stone-700 md:block"
                                  }),
                              JsxRuntime.jsx("span", {
                                    children: formatter.format(new Date("2023-03-27")),
                                    className: "text-sm text-stone-600 md:text-base md:text-inherit"
                                  })
                            ],
                            className: "mb-2 flex w-full flex-col justify-between md:flex-row md:items-center"
                          })
                    }),
                JsxRuntime.jsx("li", {
                      children: JsxRuntime.jsxs(NavLink.make, {
                            href: "/blog/two-counters",
                            children: [
                              JsxRuntime.jsx("span", {
                                    children: "Two counters"
                                  }),
                              JsxRuntime.jsx("span", {
                                    className: "m-2 hidden h-[2px] flex-1 bg-stone-300 dark:bg-stone-700 md:block"
                                  }),
                              JsxRuntime.jsx("span", {
                                    children: formatter.format(new Date("2023-03-27")),
                                    className: "text-sm text-stone-600 md:text-base md:text-inherit"
                                  })
                            ],
                            className: "mb-2 flex w-full flex-col justify-between md:flex-row md:items-center"
                          })
                    }),
                JsxRuntime.jsx("li", {
                      children: JsxRuntime.jsxs(NavLink.make, {
                            href: "/blog/docker-registry-trough-traefik",
                            children: [
                              JsxRuntime.jsx("span", {
                                    children: "Docker Registry trough Traefik"
                                  }),
                              JsxRuntime.jsx("span", {
                                    className: "m-2 hidden h-[2px] flex-1 bg-stone-300 dark:bg-stone-700 md:block"
                                  }),
                              JsxRuntime.jsx("span", {
                                    children: formatter.format(new Date("2023-03-27")),
                                    className: "text-sm text-stone-600 md:text-base md:text-inherit"
                                  })
                            ],
                            className: "mb-2 flex w-full flex-col justify-between md:flex-row md:items-center"
                          })
                    })
              ],
              className: "list-roman pl-5 pt-20",
              reversed: true
            });
}

var config_title = "Blog";

var config_useBaseLayout = true;

var config_statusCode = 200;

var config_getProps = (async function () {
    return ["Foo"];
  });

var config = {
  title: config_title,
  useBaseLayout: config_useBaseLayout,
  statusCode: config_statusCode,
  getProps: config_getProps
};

var make = Index;

export {
  make ,
  config ,
}
/* NavLink Not a pure module */
